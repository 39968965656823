import React from 'react';
import Link from 'next/link';
import { Path } from './constants/paths';
import styles from './styles/Navbar.module.scss';
import { FormattedMessage } from 'react-intl';
import { useRouter } from 'next/router';
import useSession from '../../hooks/useSession';

export function NavbarLinks(): JSX.Element {
    const router = useRouter();
    const { session } = useSession();
    const isInZeroState = session?.assignedToBounty === 'no' && session?.assignedToRecurring === 'no';

    const isPageActive = (path: Path): string => {
        if (path === '/') {
            return router.pathname === '/' ? styles.active : '';
        } else {
            const expression = `${path}.*`;
            const regexp = new RegExp(expression, 'g');

            return regexp.test(router.pathname) ? styles.active : '';
        }
    };

    const promotionalMaterialsLink = (): JSX.Element => {
        if (isInZeroState) { return null; }

        return (
            <Link href={Path.PromotionalMaterials} className={`${styles.navigationLink} ${isPageActive(Path.PromotionalMaterials)}`}>
                <FormattedMessage id="Navbar.Links.PromotionalMaterials"/>
            </Link>
        );
    };

    return (
        <div className={styles.navigationLinks}>
            <Link href={Path.Dashboard} className={`${styles.navigationLink} ${isPageActive(Path.Dashboard)}`}>
                <FormattedMessage id="Navbar.Links.Dashboard"/>
            </Link>

            {promotionalMaterialsLink()}
        </div>
    );
}
